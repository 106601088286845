<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="3" class="pb-0">
        <span class="text-h5">Отчет по должникам</span>
      </v-col>
      <v-col cols="9" class="pb-0">
        <table class="totals-info font-weight-bold text-right float-end">
          <tr style="vertical-align: top; padding-right: 15px;padding-left: 15px;">
            <td rowspan="2" class="success--text text--darken-3 mr-3" style="padding-right: 15px;">Дебет:</td>
            <td>
              <span class="success--text text--darken-3 mr-4">{{reportTotals.debet_summa_uzs|money}}</span>
            </td>
            <td rowspan="2" class="error--text text--darken-2 mr-3" style="padding-right: 15px;">Кредит:</td>
            <td>
              <span class="text-wrap error--text text--darken-2 mr-4">{{reportTotals.credit_summa_uzs|money}}</span>
            </td>
            <td rowspan="2" style="padding-right: 15px;padding-left: 15px;">Разница:</td>
            <td>
              <span>{{reportTotals.difference_total_uzs|money}}</span>
            </td>
            <td rowspan="2" style="padding-right: 15px;padding-left: 15px;"
                :class="reportTotals.differance > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'"
            >Итог:</td>
            <td rowspan="2" :class="reportTotals.differance > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'">
              <span>{{reportTotals.differance|money}}</span>
            </td>
          </tr>
          <tr style="vertical-align: top;">
            <td>
              <span class="success--text text--darken-3 mr-4">
                {{reportTotals.debet_summa_usd|money('usd')}}
              </span>
            </td>
            <td>
              <span class="error--text text--darken-2 mr-4">
                {{reportTotals.credit_summa_usd|money('usd')}}
              </span>
            </td>
            <td>
              <span>{{reportTotals.difference_total_usd|money('usd')}}</span>
            </td>
          </tr>
          <tr></tr>
          <tr></tr>
        </table>
      </v-col>
      <v-col cols="3" class="py-0">
        <p class="mb-0 v-label theme--light toggler-label">Тип долга</p>
        <v-btn-toggle v-model="filter.type"
          group dense mandatory :value-comparator="typeComparator"
          :color="filter.type === 1 ? 'success' : (filter.type === 0 ? 'error' : 'primary')"
        >
          <v-btn class="mx-0" value="all">
            <span>все</span>
          </v-btn>
          <v-btn class="mx-0" value="credit">
            <span>заемщики</span>
          </v-btn>
          <v-btn class="mx-0" value="debet">
            <span>должники</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-autocomplete v-model="filter.region"
                        :items="regions" @change="filter.district = null"
                        item-value="id" item-text="title"
                        label="Область" hide-details clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-autocomplete v-model="filter.district"
                        :items="getDistricts(filter.region)"
                        item-value="id" item-text="title"
                        label="Район / Город" hide-details clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-autocomplete v-model="filter.counteragent" :items="counteragents"
                        item-value="id" item-text="full_name"
                        prepend-inner-icon="mdi-account-group" label="Контрагент"
                        :loading="!!counteragentsLoading" :disabled="!!counteragentsLoading"
                        clearable hide-details multiple
        >
          <template v-slot:selection="{item, index}">
            <span v-if="index === 0">{{item.full_name}}</span>
            <span class="pl-1" v-if="index === 1">
              и еще {{filter.counteragent.length - 1}}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-menu v-model="toDateMenu" :close-on-content-click="false"
                transition="scale-transition" bottom left max-width="290px" min-width="auto"
                offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="дата" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.to_date" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table :items="report"
                  :headers="headers" :loading="!!fetching" :items-per-page="filter.limit"
                  :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
                  no-data-text="Нет данных" loading-text="Данные загружаются"
                  class="printable-table"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>
            <a class="link-like text-decoration-none" :href="getCounterAgentLink(item.id)" target="_blank">
              {{item.full_name}}
            </a>
          </td>
          <td class="text-center">{{item.inn}}</td>
          <td class="text-right">{{item.debet_sum|money(null, 0, false)}}</td>
          <td class="text-right">{{item.credit_sum|money(null, 0, false)}}</td>
          <td class="text-right"
              :class="item.differance_sum > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'"
          >{{item.differance_sum|money(null, 0, false)}}</td>
          <td class="text-right">{{item.debet_usd|money('usd', 0, false)}}</td>
          <td class="text-right">{{item.credit_usd|money('usd', 0, false)}}</td>
          <td class="text-right"
              :class="item.differance_usd > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'"
          >{{item.differance_usd|money('usd', 0, false)}}</td>
          <td class="text-right"
              :class="item.differance_final > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'"
          >{{item.differance_final|money(null, 0, false)}}</td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages" :extra-page-size="{id: 1000, text: 'Все'}">
      <template #right>
        <v-btn text color="primary" @click="print()">
          <v-icon>mdi-printer</v-icon> Печать
        </v-btn>
      </template>
    </pagination>
  </v-container>
</template>

<script>
  import {mapGetters, mapState as mapStateDefault, createNamespacedHelpers} from 'vuex'
  import moment from 'moment'
  import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'

  const {mapState, mapActions} = createNamespacedHelpers('Report')

  import {hasFilter, hasDialog} from '@/mixins'

  export default {
    name: 'report-collection',

    mixins: [hasFilter, hasDialog],

    customFilters: {
      to_date: moment().format(SERVER_DATE_FORMAT),
      region: null,
      district: null,
      type: 'all',
      report: 'loaners',
      counteragent: [],
    },

    data() {
      return {
        toDateMenu: false,
        fromDateMenu: false,
      }
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState([
        'fetching',
        'data',
      ]),

      ...mapStateDefault('Location', {
        regions: state => state.regions?.results || [],
      }),


      ...mapStateDefault('Counteragent', {
        counteragents: state => state.data?.results || [],
        counteragentsLoading: state => !!state.fetching,
      }),

      ...mapGetters('Location', ['getDistricts']),

      headers() {
        return [
          {text: '#', align: 'center', width: '100'},
          {text: 'Название', width: '200px'},
          {text: 'ИНН', align: 'center'},
          {text: 'Дебет, сум', align: 'right'},
          {text: 'Кредит, сум', align: 'right'},
          {text: 'Разница', align: 'right'},
          {text: 'Дебет, $', align: 'right'},
          {text: 'Кредит, $', align: 'right'},
          {text: 'Разница', align: 'right'},
          {text: 'Итог', align: 'right'},
        ]
      },

      report() {
        return this.data?.results || []
      },

      reportTotals() {
        return this.data?.total || {}
      },

      toDateFormatted() {
        return moment(this.filter.to_date).format(USER_DATE_FORMAT)
      },

      fromDateFormatted() {
        return this.filter.from_date && moment(this.filter.from_date).format(USER_DATE_FORMAT)
      },
    },

    methods: {
      ...mapActions(['fetch']),

      dateChanged() {
        this.toDateMenu = false
        this.fromDateMenu = false
      },

      typeComparator(curr, item) {
        return curr === item
      },

      getCounterAgentLink(id) {
        return this.$router.resolve({
          name: 'counteragent.list',
          query: {counteragent_id: id}
        }).href
      },

      print() {
        window.print()
      }
    },

    mounted() {
      this.$store.dispatch('Counteragent/fetch')
      this.$eventBus.$emit('setTitle', 'Отчет по должникам')
    },
  }
</script>

<style>
  .toggler-label {
    height: 15px;
    font-size: 0.75rem;
    font-weight: 400;
  }
  .totals-info {
    white-space: nowrap !important;
    font-size: 0.9rem;
  }
</style>
